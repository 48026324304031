import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import keycrime_logo from '../../assets/images/projects/service/keycrime/keycrime-logo.png'
import video1 from '../../assets/images/projects/service/keycrime/outcome/26.mp4'
import { useRef } from 'react'



const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignKC = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
  
  <Layout>
    <Helmet>
      <title>Keycrime</title>
      <meta
        name="Service Design - Keycrime"
        content="Redesigning web application for criminal investigation"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.kccover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <h1>keycrime.</h1>
            <h3>
              We redesigned an operative and analytical web application of
              Predictive Policing that improved the workflow efficiency &
              effectiveness of police officers. It allowed them to fully focus
              on the investigation and predict when, where and how a criminal is
              likely to hit next, thus improving their safety and optimising the
              dispatch of resources.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>
                  with me for more details related to this project; such as my
                  role, the problems i faced, the processes i used and how i
                  addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products & services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Keycrime was created and developed to fight robberies in the
                  metropolitan area of Milan. It combines the investigation and
                  analytical experience of police officers with complex
                  algorithms to provide them with accurate crime forecasting,
                  allowing them to track criminal behaviour and catch them
                  before the next crime.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2018 - 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 5 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Redesigning a web application
                  and a dashboard for criminal investigation.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Researching of inspiration on UX &
                  UI elements, mapping of Information Architecture, wireframing
                  of screens, writing script and sketching storyboard for video,
                  visual design consultation and prototyping of application on
                  Adobe XD.
                </span>
                <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <img
                      className="span-4"
                      src={keycrime_logo}
                      alt="keycrime logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.kcchallenge.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Our aim was to redesign the interaction, user experience and
              visual design of the existing web application so that all its
              product functionalities are easy to use. This improvement in the
              workflow will also allow for the product to be scaled and used by
              police officers in different cities in the world.
            </p>

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>


          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>
            <div className="process-image">
              <Img fluid={props.data.kc_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              A complex software & technology, it was important to start by
              fully understanding the mission and reason behind why KeyCrime
              came into existence.
            </p>
            <p className="full-text">
              KeyCrime was born about ten years ago founded by a policeman,
              today CEO of the company, Mario Venturi. A step up in crime
              analysis & investigation, it analyses different kind of crimes
              such as robbery, sexual violence, theft, homicide and scam. To get
              a comprehensive explanation about how KeyCrime works check the
              video: https://youtu.be/zIjnNtE93MU
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess2.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess3.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess5.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess6.childImageSharp.fluid} />
            </Slider>
            <p className="full-text">
              This followed a deep dive session to understand how the existing
              software worked both from the front and back-end side. The old
              interface of the application is shown in the pictures above. After
              understanding all the functionalities and the workflow of the
              police officers; an information architecture of the product was
              mapped out.
            </p>
            <p className="full-text">
              This structure formed the basis for making the wireframes. The
              design of each screen took into account the hierarchy of
              information and functionalities according to the needs of the
              police officers to carry out their tasks.
            </p>
            <h4>Data input of information into the system</h4>
            <p className="full-text">
              KeyCrime works by first entering already committed crime
              information into the system, such as time, date, place & type of
              crime. The forms were designed to allow for logical and quick
              insertion of data. This meant grouping related information in one
              card, showing all selection options if under 6, using field length
              as an affordance, using auto-complete for longer fields and
              correctly using radio buttons, dropdowns & checkboxes depending on
              the type of information that had to be inputted.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess7.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess8.childImageSharp.fluid} />
              </div>
            </div>
            <div className="process">
              <div className="process-left">
                <p>
                  Other types of information such as facial appearance, body,
                  clothes, accessories, weapons & vehicles can then be added for
                  the criminal event. The input of these type of data required a
                  kind of interaction which had a quick navigation between the
                  different information categories; in this case parts of the
                  body.
                </p>
              </div>
              <div className="process-right">
                <p>
                  Tags were used as interaction design elements to simplify the
                  experience of first inserting high level information, then
                  showing the contingent section of the form to insert more
                  detailed information with clear communication of the next step
                  the user has to take. Different colours were used to show the
                  different states of tags such as empty, selected & filled.
                </p>
              </div>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess9.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess10.childImageSharp.fluid} />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.kcprocess11.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              This same design consistency was maintained for inputting data
              related to weapons and vehicles. In addition to that the input
              fields were also made keyboard-friendly to increase its
              accessibility; “tab” was used to quickly move through the
              different fields. As this was a desktop-only product, mouse
              hover-over interaction was used to show more info and right-click
              interaction showed secondary actions that could be taken.
            </p>
            <p className="full-text">
              Media gallery is a key part of KeyCrime product. Police officers
              upload images and videos from the crime event into the system to
              see, analyse and identify different elements for clues/evidence.
            </p>
            <p className="full-text">
              They needed to do this in a way where they could identify the same
              element in all of the pictures & videos at once. To meet this
              requirement, a custom tagging interaction was designed; where they
              had to choose the element to identify (e.g. red cap) and then the
              “red cap” tag can be added to parts of the image and video they
              click on.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess12.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess13.childImageSharp.fluid} />
            </Slider>
            <h4>Visual Design</h4>
            <p className="full-text">
              After wireframes of key screens were finalised, the visual
              language was defined. 2 concepts for the look & feel of the
              product were made; dark and light. Finally the light theme was
              chosen as it made the insertion of data fields, scannability of
              information and visualisation of data easier on the eye.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess14.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess15.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess16.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess17.childImageSharp.fluid} />
            </Slider>
            <h4>Speech to text input of information & review</h4>
            <p className="full-text">
              In order to predict behavior of criminals, KeyCrime collects data
              from reports as well as from interviews designed by psychologists.{' '}
            </p>
            <p className="full-text">
              In order to make this process seamless and natural, voice
              recognition was used so that witness interviews could be
              automatically converted from speech to text. This functionality
              sped up the process and reduced the chance of human error that
              used to occur while listening, remembering and typing the
              information they heard.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess18.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess19.childImageSharp.fluid} />
              </div>
            </div>
            <div className="process">
              <div className="process-left">
                <p>
                  We went one step beyond by designing a user experience where
                  keywords in the interview were detected and auto-filled
                  directly into the forms. The design then gave the officers the
                  ability to accept the automatically filled information with a
                  single click.
                </p>
              </div>
              <div className="process-right">
                <p>
                  We further designed a system that suggests officers
                  behavioural questions to ask based on the conversation flow it
                  hears between them & witness. Overall this UX and interaction
                  design saved the officer time and mental effort allowing to
                  focus on other things related to the interview.
                </p>
              </div>
            </div>
            <h4>Designing of search results and map visualisations</h4>
            <p className="full-text">
              Keycrime’s strategic partnership with IBM watson for its AI
              capabilities has enabled efficient & reliable analysis of all the
              data in the database. The software formulates links between
              different events and data sets to help link crimes and identify
              criminal series.
            </p>
            <p className="full-text">
              To allow officers to do this effectively, the search results were
              made up of tabs that grouped information into categories allowing
              for large amount of information to be concisely represented and
              easily accessible.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess20.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess21.childImageSharp.fluid} />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.kcprocess22.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              A custom method was designed to facilitate the comparison of the
              different search results. By changing the tabs or scrolling down
              in any of the search result; the same thing is seen on all other
              search results. This interaction made it quick to analyse and
              compare between events at a glance.
            </p>
            <h4>Storyboard</h4>
            <p className="full-text">
              Finally, we made a 90 second explanation video about KeyCrime
              which could be shown to police agencies of other cities and
              potential investors.
            </p>
            <p className="full-text">
              We started by first listing down the main functionalities and
              values we had to communicate in this video. This was followed by
              making rough sketches of the frames in the storyboard and writing
              voice over script. Inspiration research regarding the visual style
              of the video and illustrations was then made. Finally, the rough
              sketches were illustrated and all the scenes were animated.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess23.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess24.childImageSharp.fluid} />
              </div>
            </div>
          </section>

          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <p>
                We successfully improved the usability of the KeyCrime web
                application enhancing the workflow of the police officers so
                that they can fully focus on the investigation and predict when,
                where and how the criminal is likely to hit next.
              </p>
              <h3>
                The user experience of the application was completely redesigned
                to make it easy to organise the huge amount of criminal event
                data in a easy-to-consult database.
              </h3>
              <h3>
                The simplified Interface allowed for easy usage of the various
                features of the application such as quick input different types
                of information & tagging of Video/Photo & Documents and and
                optimal navigability to access different sections and details of
                the application.
              </h3>
              <h3>
                In certain sections of the application custom interactions were
                designed to meet the specific needs of the police officers to do
                their job effectively and efficiently.
              </h3>
              <p>
                Finally, we also made sure that the newly designed application
                had a short learning curve so that it can be easily adopted by
                the current police forces in Milan and in other cities globally.
              </p>
              <p>
                Visit <a href="https://keycrime.com ">keycrime.com </a> to find
                out more.
              </p>
            </div>
            <div className="video">
              <video controls>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
            <div className="image">
              <Img fluid={props.data.kcoutcome27.childImageSharp.fluid} />
            </div>
          </section>
        </div>
      </div>
      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.kcoutcome25.childImageSharp.fluid} />
        </div>
      </section>
      <section id="footertopcta" className="inner">
        <p className="text arrow-back">
          head back to
          <br />
          <Link to="/work">all my work.</Link>
        </p>
      </section>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1)</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-muvisi-private">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">muvisi</div>
                <div className="text">
                  A mobility service that makes it stress-free & convenient to
                  find parking spots in the city.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
}

export default ServiceDesignKC

export const fluidKCImage = graphql`
  fragment fluidKCImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    kccover: file(
      relativePath: {
        eq: "projects/service/keycrime/cover/keycrime-cover-image.jpg"
      }
    ) {
      ...fluidKCImage
    }
    things_logo: file(
      relativePath: { eq: "projects/service/tech/things-logo.jpg" }
    ) {
      ...fluidKCImage
    }
    keycrime_logo: file(
      relativePath: { eq: "projects/service/keycrime/keycrime-logo.png" }
    ) {
      ...fluidKCImage
    }
    kcchallenge: file(
      relativePath: { eq: "projects/service/keycrime/challenge/1.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess2: file(
      relativePath: { eq: "projects/service/keycrime/process/2.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess3: file(
      relativePath: { eq: "projects/service/keycrime/process/3.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess4: file(
      relativePath: { eq: "projects/service/keycrime/process/4.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess5: file(
      relativePath: { eq: "projects/service/keycrime/process/5.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess6: file(
      relativePath: { eq: "projects/service/keycrime/process/6.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess7: file(
      relativePath: { eq: "projects/service/keycrime/process/7.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess8: file(
      relativePath: { eq: "projects/service/keycrime/process/8.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess9: file(
      relativePath: { eq: "projects/service/keycrime/process/9.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess10: file(
      relativePath: { eq: "projects/service/keycrime/process/10.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess11: file(
      relativePath: { eq: "projects/service/keycrime/process/11.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess12: file(
      relativePath: { eq: "projects/service/keycrime/process/12.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess13: file(
      relativePath: { eq: "projects/service/keycrime/process/13.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess14: file(
      relativePath: { eq: "projects/service/keycrime/process/14.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess15: file(
      relativePath: { eq: "projects/service/keycrime/process/15.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess16: file(
      relativePath: { eq: "projects/service/keycrime/process/16.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess17: file(
      relativePath: { eq: "projects/service/keycrime/process/17.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess18: file(
      relativePath: { eq: "projects/service/keycrime/process/18.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess19: file(
      relativePath: { eq: "projects/service/keycrime/process/19.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess20: file(
      relativePath: { eq: "projects/service/keycrime/process/20.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess21: file(
      relativePath: { eq: "projects/service/keycrime/process/21.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess22: file(
      relativePath: { eq: "projects/service/keycrime/process/22.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess23: file(
      relativePath: { eq: "projects/service/keycrime/process/23.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess24: file(
      relativePath: { eq: "projects/service/keycrime/process/24.jpg" }
    ) {
      ...fluidKCImage
    }
    kcoutcome25: file(
      relativePath: { eq: "projects/service/keycrime/outcome/25.png" }
    ) {
      ...fluidKCImage
    }
    kcoutcome27: file(
      relativePath: { eq: "projects/service/keycrime/outcome/27.jpg" }
    ) {
      ...fluidKCImage
    }
    kc_process: file(
      relativePath: { eq: "projects/process/keycrime-process.png" }
    ) {
      ...fluidKCImage
    }
    project1: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidKCImage
    }
    project2: file(relativePath: { eq: "projects/19.png" }) {
      ...fluidKCImage
    }
  }
`
